import styled from "styled-components";

export const Footer = styled.footer`
  background-color: #274B2B;
  color: #fff;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 120px 15px;
  box-sizing: border-box;

  @media (max-width: 616px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  &:first-child {
    width: 300px;
  }

  &:last-child {
    flex: 1;

    @media (max-width: 616px) {
      width: 100%;
    }
  }
`;

export const LogoContent = styled.div``;

export const Logo = styled.div``;

export const Copyright = styled.p`
  font-size: 12px;
`;

export const Social = styled.div`
  display: flex;
  gap: 15px;

  @media (max-width: 616px) {
    margin-bottom: 30px;
    gap: 10px;
    justify-content: center;
  }

  a {
    text-decoration: none;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: transparent;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s linear;
    color: #fff;

    svg {
      fill: #fff;
      transition: all .2s linear;
    }

    &:hover {
      background-color: #fff;
      
      svg {
       fill: #59A059;
      }
    }
  }
`;

export const Links = styled.div`
  padding-left: 60px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  justify-content: center;
  flex: 1;
  gap: 10px;

  @media (max-width: 616px) {
    padding-left: 0;
  }

  ul {
    margin: 0;
    list-style: none;
    padding-left: 0;

    li {
      font-size: 0.938rem;
      font-weight: 300;

      a {
        color: #fff;
      }

      &.header {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 5px;
      }
    }
  }
`;
